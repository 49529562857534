import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import CalendarService from "../../services/CalendarService";
import {ArrowLeftIcon, MailIcon, PhoneIcon, UserIcon} from "@heroicons/react/solid";

const _calendarService = new CalendarService();
export default function EventsSlideOverComponent({date, events, onClose}) {

    const [selectedEvent, setSelectedEvent] = useState(null)

    function getEventDisplayTime(e) {
        return _calendarService.formatEventDates(e)
    }

    function isSelectedEventBlank() {
        return selectedEvent == null || (
            !selectedEvent.location &&
            !selectedEvent.caseManager &&
            !selectedEvent.description);
    }

    return (
        <Transition.Root show={date != null} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={() => {
                setSelectedEvent(null)
                onClose();
            }}>
                <div className="fixed inset-0"/>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="bg-primary-700 py-6 px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title
                                                    className="text-lg font-medium text-white">{
                                                    selectedEvent ? selectedEvent.title : 'Events'
                                                }</Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={() => selectedEvent ? setSelectedEvent(null) : onClose(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        {selectedEvent &&
                                                            <ArrowLeftIcon className="h-6 w-6" aria-hidden="true"/>}
                                                        {!selectedEvent &&
                                                            <XIcon className="h-6 w-6" aria-hidden="true"/>}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-primary-300">
                                                    {
                                                        selectedEvent ? getEventDisplayTime(selectedEvent) : date}
                                                </p>
                                            </div>
                                        </div>
                                        {!selectedEvent && events.length === 0 &&
                                            <div className="relative text-center text-lg text-gray-500 p-10">No events
                                                scheduled</div>}
                                        {!selectedEvent && <div className="relative flex-1 ">
                                            <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                                                {events.map((event) => (
                                                    <li key={event.id}>
                                                        <div className="group relative flex items-center py-6 px-5">
                                                            <a href="#" onClick={() => setSelectedEvent(event)}
                                                               className="-m-1 block flex-1 p-1 cursor-pointer">
                                                                <div className="absolute inset-0 group-hover:bg-gray-50"
                                                                     aria-hidden="true"/>
                                                                <div
                                                                    className="relative flex min-w-0 flex-1 items-center">
                                                                    <div className="ml-4 truncate">
                                                                        <p className="truncate text-sm font-medium text-gray-900">{event.title}</p>
                                                                        <p className="truncate text-sm text-gray-500">{getEventDisplayTime(event)}</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>}
                                        {selectedEvent && <div className="px-4 py-5 sm:px-0 sm:py-0">
                                            <div className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                                                {selectedEvent.caseManager && <div className="sm:flex sm:px-6 sm:py-5">
                                                    <div
                                                        className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                                        Case Manager
                                                    </div>
                                                    <div
                                                        className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                                        <div className="flex">
                                                            <UserIcon
                                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                aria-hidden="true"/>
                                                            <p>{selectedEvent.caseManager.name}</p>
                                                        </div>
                                                        <a className="flex mt-1"
                                                           href={"mailto:" + selectedEvent.caseManager.email + "?subject=" + selectedEvent.title}>
                                                            <MailIcon
                                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                aria-hidden="true"/>
                                                            <p>{selectedEvent.caseManager.email}</p>
                                                        </a>
                                                        {selectedEvent.caseManager.phone_number &&
                                                            <a className="flex mt-1"
                                                               href={"tel:" + selectedEvent.caseManager.phone_number}>
                                                                <PhoneIcon
                                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"/>
                                                                <p>{selectedEvent.caseManager.phone_number}</p>
                                                            </a>}
                                                    </div>
                                                </div>}
                                                {selectedEvent.description && <div className="sm:flex sm:px-6 sm:py-5">
                                                    <div
                                                        className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Notes
                                                    </div>
                                                    <div
                                                        className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                                        <p>
                                                            {selectedEvent.description}
                                                        </p>
                                                    </div>
                                                </div>}
                                                {isSelectedEventBlank() &&
                                                    <div className="sm:px-6 sm:py-5 ">
                                                        <p className="mt-1 text-sm text-gray-900 sm:mt-0 text-center">
                                                            No information is available.
                                                        </p>
                                                    </div>}
                                            </div>
                                        </div>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
