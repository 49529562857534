import React from 'react'
import logo from '../logo.png';
import {useLocation, useNavigate} from "react-router-dom";

function SuccessForm() {

    const navigate = useNavigate();
    const location = useLocation();
    const addMoreActivity = () => {
        navigate('/time-entry', {
            replace: true,
            state: location.state
        });
    }
    return (
        <div id="legacy-container" className="mt-12">
            <img src={logo} alt="Logo" className="central" id="logo"/>
            <h2 className="container-header">Time Entry Successful!</h2>
            <div className="central spacing"></div>
            <button onClick={addMoreActivity} className="central btn legacy-lg-btn">Add Activity</button>
        </div>
    )
}

export default SuccessForm;