import {User} from '../models/User';
import Api from "./Api";

export default class EnvProvider {
    private readonly prod: boolean;
    private readonly v1Api: string;
    private readonly v2Api: string;
    private _user: User | undefined;
    private _activities:any[] = [];
    private _caseManagers:any[] = [];

    constructor() {
        this.prod = `${process.env.REACT_APP_PROD}`.trim() === 'true';
        if (this.prod) {
            this.v1Api = 'https://timeapi.jurismedicus.net';
            this.v2Api = 'https://jm.ktech.dev/api';
        } else {
            this.v1Api = `https://7vinxrltla.execute-api.us-east-2.amazonaws.com/${process.env.REACT_APP_API_STAGE}`;
            this.v2Api = 'http://localhost:8666/api';
        }
    }


    get user(): User | undefined {
        return this._user;
    }

    get activities(): any[] {
        return this._activities;
    }

    get caseManagers(): any[] {
        return this._caseManagers;
    }

    set user(value: User | undefined) {
        this._user = value;
    }

    set activities(value: any[]) {
        this._activities = value;
    }

    set caseManagers(value: any[]) {
        this._caseManagers = value;
    }

    public buildApi(user: User | undefined): Api {
        return new Api({
            baseUrl: this.v2Api,
            platform: 'web',
            version: `${process.env.REACT_APP_VERSION}`,
            user: user || this._user
        });
    }

    public buildLegacyApi(): Api {
        return new Api({
            baseUrl: this.v1Api,
            platform: 'web',
            version: `${process.env.REACT_APP_VERSION}`,
            user: this._user,
        });
    }
}
