import {classNames} from "@headlessui/react/dist/utils/class-names";

export default function CalendarDaySmComponent({date, isSelected, isToday, isCurrentMonth, events, hideDetails, onSelect}) {
    
    return (<button
        key={date}
        type="button"
        onClick={onSelect}
        className={classNames(
            isCurrentMonth ? 'bg-white' : 'bg-gray-50',
            (isSelected || isToday) && 'font-semibold',
            isSelected && 'text-white',
            !isSelected && isToday && 'text-primary-600',
            !isSelected && isCurrentMonth && !isToday && 'text-gray-900',
            !isSelected && !isCurrentMonth && !isToday && 'text-gray-500',
            'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
        )}
    >
        <time
            dateTime={date}
            className={classNames(
                isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                !hideDetails && isSelected && isToday && 'bg-primary-600',
                !hideDetails && isSelected && !isToday && 'bg-gray-900',
                'ml-auto'
            )}
        >
            {hideDetails ? '' : date.split('-').pop().replace(/^0/, '')}
        </time>
        <span className="sr-only">{events.length} events</span>
        {events.length > 0 && (
            <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {events.map((event) => (
                        <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full" style={{backgroundColor: event.color}}/>
                    ))}
                  </span>
        )}
    </button>)
}
