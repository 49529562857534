import {classNames} from "@headlessui/react/dist/utils/class-names";

export default function CalendarDayLgComponent({date, isSelected, isToday, isCurrentMonth, events, hideDetails, onSelect}) {

    return (<div
        key={date}
        onClick={onSelect}
        className={classNames(
            isCurrentMonth || hideDetails ? 'bg-white' : 'bg-gray-50 text-gray-500',
            'relative py-2 px-3 h-60 cursor-pointer',
            isSelected ? 'shadow ring-1 ring-black ring-opacity-20 ' : ''
        )}
    >
        <time
            dateTime={date}
            className={
                isToday && !hideDetails
                    ? 'flex h-6 w-6 items-center justify-center rounded-full bg-primary-600 font-semibold text-white'
                    : undefined
            }
        >
            {hideDetails ? '' : date.split('-').pop().replace(/^0/, '')}
        </time>
        {!hideDetails && events.length > 0 && (
            <ol className="mt-2">
                {events.slice(0, 5).map((event) => (
                    <li key={event.id} className={'border border-t-0'}>
                        <a href={event.href} className="group flex">
                            <p className="flex-auto truncate font-medium text-gray-900 p-1"
                               style={{backgroundColor: event.color}}>
                                {event.title}
                            </p>
                        </a>
                    </li>
                ))}
                {events.length > 5 &&
                    <li className="text-gray-500">+ {events.length - 5} more</li>}
            </ol>
        )}
    </div>)
}
