import React, {useState} from 'react';
import EnvProvider from "./api/EnvProvider";
import SuccessForm from "./components/SuccessForm";
import LoginForm from "./components/LoginForm";
import AuthProvider from "./security/AuthProvider";
import {Route, Routes} from "react-router-dom";
import Layout from "./components/layout/Layout";
import TodoScreen from "./components/TodoScreen";
import RequireAuth from "./security/RequireAuth";
import CalendarComponent from "./components/calendar/CalendarComponent";
import TimeEntryForm from "./components/time-entry/TimeEntryForm";

export const EnvContext = React.createContext({});

function App() {
    const [env] = useState(new EnvProvider());
    React.useEffect(() => {
        // Do something on startup here.
    }, [env]);
    return (
        <EnvContext.Provider value={env}>
            <AuthProvider>
                <Routes>
                    <Route element={<Layout />}>
                        <Route path="/login" element={<LoginForm/>}/>
                        <Route path="/" element={<RequireAuth><TimeEntryForm /></RequireAuth>} />
                        <Route path="/time-entry" element={<RequireAuth><TimeEntryForm /></RequireAuth>} />
                        {/*<Route path="/time-entry/v1" element={<RequireAuth><EntryForm /></RequireAuth>} />*/}
                        <Route path="/success" element={<RequireAuth><SuccessForm /></RequireAuth>} />
                        <Route path="/calendar" element={<RequireAuth><CalendarComponent /></RequireAuth>} />
                        <Route path="/todo" element={<RequireAuth><TodoScreen /></RequireAuth>} />
                    </Route>
                </Routes>
            </AuthProvider>
        </EnvContext.Provider>
    )

}

export default App;
