import {EnvContext} from "../App";
import LoginService from "../services/LoginService";
import React, {useContext, useState} from "react";
import EnvProvider from "../api/EnvProvider";


interface AuthContextType {
    authenticated: boolean;
    login: (user: string, successCallback: VoidFunction, errorCallback: (any:any) => {}) => void;
    logout: (callback: VoidFunction) => void;
}

export const  AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({children}: { children: React.ReactNode }) {
    const envProvider: EnvProvider = useContext(EnvContext) as EnvProvider;
    const loginService = new LoginService(envProvider)
    let [authenticated, setAuthenticated] = useState(false);

    const login = (email: string, callback: VoidFunction, onFailure: (any:any) => {}) => {
        console.log("Logging in ", email)
        return loginService.initSession({
            "email": email
        }, () => {
            setAuthenticated(true);
            callback();
        }, onFailure);
    };

    let logout = (callback: VoidFunction) => {
        setAuthenticated(false);
        envProvider.user = undefined;
        callback();
    };

    let value = {authenticated, login, logout};

    // @ts-ignore
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}