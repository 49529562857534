function handleError(error, callback) {
    if (error.response) {
        console.log(
            'API Error',
            '\nData\n',
            error.request,
            '\nResponse\n',
            error.response,
            error.response.data,
        );
        if (error.response.data && error.response.data.message) {
            callback(error.response.data.message);
            return;
        }
    } else {
        console.log('API Error', '\nData\n', error.request, '\nResponse\n', error);
    }
    callback(null);
}

export default class CaseEntryService {
    constructor(envProvider) {
        this.envProvider = envProvider;
    }

    delay() {
        const envProvider = this.envProvider;
        return function (x) {
            if (envProvider.prod) {
                return Promise.resolve(x);
            } else {
                return new Promise(resolve => setTimeout(() => resolve(x), 10));
            }
        };
    }

    post(data, onSuccess, onFailure) {
        data.userId = this.envProvider.user.id;
        this.envProvider
            .buildApi()
            .post('/time-entry', data)
            .then(response => {
                console.log('getting data from API', response.data);
                onSuccess();
            })
            .catch(error => {
                handleError(error, onFailure);
            });
    }

    getMatters(onSuccess, onFailure, onComplete) {
        const url = '/time-entry/recent-matters';
        console.log('Sending Request: ', url);
        this.envProvider
            .buildApi()
            .get(url)
            .then(this.delay())
            .then(response => onSuccess(response.data))
            .catch(error => handleError(error, onFailure))
            .finally(onComplete);
    }
}
