import {Outlet} from "react-router-dom";
import NavBar from "./NavBar";
import {useContext} from "react";
import {AuthContext} from "../../security/AuthProvider";

export default function Layout() {
    const authProvider = useContext(AuthContext);
    return (
        <div className="w-screen h-screen flex flex-col ">
            {authProvider.authenticated && <NavBar/>}
            <div className="w-full h-full flex-1 relative">
                <Outlet/>
            </div>
        </div>
    );
}