import {ExclamationCircleIcon} from "@heroicons/react/solid";

const SUPPORT_TYPES = ['warning', 'error']
export default function AlertWithDescription({title, message, type}) {

    const getContainerStyle = () => {
        if (type === 'warning') {
            return "bg-yellow-50 border-l-4 border-yellow-400 p-4";
        }
        if (type === 'error') {
            return "bg-red-50 border-l-4 border-red-400 p-4";
        }
        throw Error("Invalid Alert Type: " + type);
    }

    const getIcon = () => {
        if (type === 'warning') {
            return <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>;
        }
        if (type === 'error') {
            return <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>;
        }
        throw Error("Invalid Alert Type: " + type);
    }
    const getTitleStyle = () => {
        if (type === 'warning') {
            return "text-lg text-yellow-800 bold mr-3"
        }
        if (type === 'error') {
            return "text-lg text-red-800 bold mr-3"
        }
        throw Error("Invalid Alert Type: " + type);

    }
    const getMessageStyle = () => {
        if (type === 'warning') {
            return "text-sm text-sm text-yellow-700"
        }
        if (type === 'error') {
            return "text-sm text-sm text-red-700"
        }
        throw Error("Invalid Alert Type: " + type);

    }

    const isSupported = () => {
        return SUPPORT_TYPES.indexOf(type) >= 0;
    }

    return (<div>
        {isSupported() && <div className={getContainerStyle()}>
            <div className="flex items-center">
                <div className="flex-shrink-0 mr-3">
                    {getIcon()}
                </div>
                {title && <p className={getTitleStyle()}>{title}</p>}
                <p className={getMessageStyle()}>{message}</p>
            </div>
        </div>}
    </div>);
}
