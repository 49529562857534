import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Buffer} from 'buffer';
import ApiConfig from './ApiConfig';

export default class Api {
    private apiConfig: ApiConfig;

    constructor(apiConfig: ApiConfig) {
        this.apiConfig = apiConfig;
    }

    private _getBaseUrl(): string {
        return this.apiConfig.baseUrl;
    }

    private _getAxiosConfig(): AxiosRequestConfig {
        // axios will always lowercase the headers: https://github.com/axios/axios/issues/413
        const headers = {
            'jm-client': `${this.apiConfig.platform}-${this.apiConfig.version}`,
        };
        if (this.apiConfig.user) {
            // @ts-ignore
            headers.Authorization = `Basic ${new Buffer(
                `${this.apiConfig.user.email}:jm`,
            ).toString('base64')}`;
        }
        return {
            headers: headers,
        };
    }

    public post<T = any>(url: string, data?: any): Promise<AxiosResponse<T>> {
        return axios.post(
            `${this._getBaseUrl()}${url}`,
            data,
            this._getAxiosConfig(),
        );
    }

    public get<T = any>(url: string): Promise<AxiosResponse<T>> {
        return axios.get(`${this._getBaseUrl()}${url}`, this._getAxiosConfig());
    }

    public head<T = any>(url: string): Promise<AxiosResponse<T>> {
        return axios.head(`${this._getBaseUrl()}${url}`, this._getAxiosConfig());
    }
}
