import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import logo from '../logo.png';
import {AuthContext} from "../security/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {LockClosedIcon} from "@heroicons/react/solid";
import LoadingSpinner from "./LoadingSpinner";

const GENERIC_ERROR_MESSAGE = 'Invalid Credentials. Please check email and try again.';
const NO_ERROR_MESSAGE = '';

function LoginForm() {
    const authProvider = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const {register, handleSubmit, getValues, setValue} = useForm();
    const [loadState, setLoadState] = useState({loading: false, errorMessage: NO_ERROR_MESSAGE});

    const submitHandler = () => {
        setLoadState({loading: true, errorMessage: NO_ERROR_MESSAGE});
        const email = getValues("email");
        authProvider.login(email, () => {
            navigate(from, {replace: true});
        }, (error) => {
            console.log("ERROR", error);
            if (error && 'message' in error) {
                setLoadState({loading: false, errorMessage: error['message']});
            } else {
                setLoadState({loading: false, errorMessage: GENERIC_ERROR_MESSAGE});
            }
        });
    }

    const ChangeEmail = e => {
        setValue("email", e.target.value);

        if (loadState.errorMessage) {
            setLoadState({...loadState, errorMessage: NO_ERROR_MESSAGE});
        }
    }

    return (
        <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8">
            {loadState.loading &&
                <LoadingSpinner showOverlay={true} classNames="justify-center items-bottom absolute align-middle h-80 pt-72"/>}
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-20 w-auto"
                        src={logo}
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                        Sign in to your account
                    </h2>
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={handleSubmit(submitHandler)}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input {...register("email")} onChange={e => ChangeEmail(e)} placeholder="Email" required
                                   type="email"
                                   autoComplete="email"
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"/>
                        </div>
                    </div>
                    {loadState.errorMessage ?
                        <p className="text-center text-red-400">{loadState.errorMessage}</p>
                        : <div></div>
                    }

                    {!loadState.loading && <div>
                        <button type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-primary-500 group-hover:text-primary-400" aria-hidden="true"/>
                </span>
                            Sign in
                        </button>
                    </div>}
                </form>
            </div>
        </div>
    )
}

export default LoginForm;