import axios from 'axios';

export default class LoginService {
    constructor(envProvider) {
        this.envProvider = envProvider;
    }

    _parseError(error) {
        if (axios.isAxiosError(error)) {
            const contentType = error.response?.headers["content-type"];
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return error.response?.data;
            }
        }
        return null;
    }


    delay() {
        const envProvider = this.envProvider;
        return function (x) {
            if (envProvider.prod) {
                return Promise.resolve(x);
            } else {
                return new Promise(resolve => setTimeout(() => resolve(x), 10));
            }
        };
    }

    initSession(data, onSuccess, onFailure) {
        this.envProvider
            .buildApi({email: data.email, id: 0})
            .post('/time-entry-app/init')
            .then(this.delay())
            .then(response => {
                console.log('getting data from aws', response.data);
                this.envProvider.activities = response.data.activities;
                this.envProvider.caseManagers = response.data.caseManagers;
                const user = {
                    email: response.data.email,
                    id: response.data.id,
                };
                this.envProvider.user = user;
                this._storeUserData(user).finally(onSuccess);
            })
            .catch(error => {
                console.log(error.response)
                onFailure(this._parseError(error));
            });
    }

    async getUser() {
        try {
            const value = localStorage.getItem('user');
            if (value !== null) {
                console.log('Got Email From Storage', value);
                return value;
            }
        } catch (e) {
            console.log('An error occurred trying to get user data', e);
        }
        return '';
    }

    /*
        @param - email is string.
    */
    async _storeUserData(user) {
        try {
            await localStorage.setItem('user', user);
            console.log('Saved User in Storage', user);
        } catch (e) {
            console.log('An error occurred trying to save user data', e);
        }
    }
};
