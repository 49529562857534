import moment from "moment";

const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const MONTH_ABBREVIATIONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DATE_FORMAT_SEPARATOR = '\u0020\u2B22\u0020';

function handleError(error, callback) {
  if (error.response) {
    console.log(
      'API Error',
      '\nData\n',
      error.request,
      '\nResponse\n',
      error.response,
      error.response.data,
    );
    if (error.response.data && error.response.data.message) {
      callback(error.response.data.message);
      return;
    }
  } else {
    console.log('API Error', '\nData\n', error.request, '\nResponse\n', error);
  }
  callback(null);
}

export default class CalendarService {
  constructor(envProvider) {
    this.envProvider = envProvider;
  }

  delay() {
    const envProvider = this.envProvider;
    return function (x) {
      if (envProvider.prod) {
        return Promise.resolve(x);
      } else {
        return new Promise(resolve => setTimeout(() => resolve(x), 10));
      }
    };
  }

  getMomentStart(event) {
    return moment(event.start);
  }

  getMomentEnd(event) {
    return moment(event.end);
  }

  getEventColor(event) {
    return  event.type && event.type.color ? event.type.color : '#acacac'
  }

  getEvents(startDate, endDate, onSuccess, onFailure, onComplete) {
    startDate = this._toIsoString(startDate);
    endDate = this._toIsoString(endDate);
    const url = `/calendar/${this.envProvider.user.id}?from=${startDate}&to=${endDate}`;
    this.envProvider
      .buildApi()
      .get(url)
      .then(this.delay())
      .then(response => {
        onSuccess(response.data);
      })
      .catch(error => {
        handleError(error, onFailure);
      })
      .finally(onComplete);
  }

  getEvent(id, onSuccess, onFailure, onComplete) {
    const url = `/calendar/events/${id}`;
    console.log('Sending Request: ', url);
    this.envProvider
      .buildApi()
      .get(url)
      .then(response => {
        console.log(`Get Event ${id}`, response.data);
        onSuccess(response.data);
      })
      .catch(error => {
        handleError(error, onFailure);
      })
      .finally(onComplete);
  }

  isBetweenInclusive(target, bound1, bound2) {
    return (
      target.isBetween(bound1, bound2) ||
      target.isSame(bound1, 'date') ||
      target.isSame(bound2, 'date')
    );
  }

  isSameDay(d1, d2) {
    if (d1 == null || d2 == null) {
      return false;
    }
    return (
      d1.getDate() === d2.getDate() &&
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth()
    );
  }

  isSameMonth(d1, d2) {
    if (d1 == null || d2 == null) {
      return false;
    }
    return d1.getMonth() === d2.getMonth();
  }

  isSameYear(d1, d2) {
    if (d1 == null || d2 == null) {
      return false;
    }
    return d1.getFullYear() === d2.getFullYear();
  }

  isSameMeridiem(d1, d2) {
    if (d1 == null || d2 == null) {
      return false;
    }
    return this.formatMeridiem(d1) === this.formatMeridiem(d2);
  }

  getDayOfWeek(date) {
    if (date == null) {
      return null;
    }
    return DAY_NAMES[date.getDay()];
  }

  getNameOfMonth(date) {
    if (date == null) {
      return null;
    }
    return MONTH_ABBREVIATIONS[date.getMonth()];
  }

  formatMeridiem(date) {
    return date.getHours() >= 12 ? 'PM' : 'AM';
  }

  formatHour(date) {
    const hour = date.getHours() % 12;
    return hour === 0 ? '12' : hour;
  }

  formatEventDates(event) {
    const start = new Date(event.start);
    const end = new Date(event.end);
    const startDateOfMonth = start.getDate();
    const nameOfStartDay = this.getDayOfWeek(start);
    const nameOfStartMonth = this.getNameOfMonth(start);
    const startMeridiem = this.formatMeridiem(start);
    const endMeridiem = this.formatMeridiem(end);
    const startHour = this.formatHour(start);
    const endHour = this.formatHour(end);
    const startMinutes = `0${start.getMinutes()}`.slice(-2);
    const endMinutes = `0${end.getMinutes()}`.slice(-2);
    const endDateOfMonth = end.getDate();
    const nameOfEndDay = this.getDayOfWeek(end);
    const nameOfEndMonth = this.getNameOfMonth(end);

    if (event.allDay) {
      if (this.isSameDay(start, end)) {
        return `${nameOfStartDay}, ${nameOfStartMonth} ${startDateOfMonth} ${DATE_FORMAT_SEPARATOR} All Day`;
      } else {
        return `${nameOfStartDay}, ${nameOfStartMonth} ${startDateOfMonth} - ${nameOfEndDay}, ${nameOfEndMonth} ${endDateOfMonth} `;
      }
    } else if (this.isSameDay(start, end)) {
      if (startMeridiem === endMeridiem) {
        return `${nameOfStartDay}, ${nameOfStartMonth} ${startDateOfMonth} ${DATE_FORMAT_SEPARATOR} ${startHour}:${startMinutes} - ${endHour}:${endMinutes} ${endMeridiem}`;
      } else {
        return `${nameOfStartDay}, ${nameOfStartMonth} ${startDateOfMonth} ${DATE_FORMAT_SEPARATOR} ${startHour}:${startMinutes} ${startMeridiem} - ${endHour}:${endMinutes} ${endMeridiem}`;
      }
    } else {
      return `${nameOfStartDay}, ${nameOfStartMonth} ${startDateOfMonth} ${DATE_FORMAT_SEPARATOR} ${startHour}:${startMinutes} ${startMeridiem} -\n${nameOfEndDay}, ${nameOfEndMonth} ${endDateOfMonth} ${DATE_FORMAT_SEPARATOR} ${endHour}:${endMinutes} ${endMeridiem}`;
    }
  }

  pad(num) {
    if (num < 10) {
      return `0${num}`;
    } else {
      return num;
    }
  }

  _toIsoString(date) {
    const timeZoneOffset = -new Date().getTimezoneOffset();
    return (
      date +
      (timeZoneOffset >= 0 ? '+' : '-') +
      this.pad(Math.floor(Math.abs(timeZoneOffset) / 60)) +
      ':' +
      this.pad(Math.abs(timeZoneOffset) % 60)
    );
  }

  utcToDateString(utcStr) {
    return utcStr.split('T')[0];
  }
}
