/* This example requires Tailwind CSS v2.0+ */
import {Disclosure} from '@headlessui/react'
import {LogoutIcon} from '@heroicons/react/outline'
import {Link, useLocation, useNavigate} from "react-router-dom";
import logo from '../../logo_ims.png';
import {useContext} from "react";
import {AuthContext} from "../../security/AuthProvider";

export default function NavBar() {
    const authProvider = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const performLogout = () => {
        authProvider.logout(() => {
            navigate('/login', {replace: true});
        });
    }

    const buildLink = (text, urls) => {
        if (typeof urls === 'string' || urls instanceof String) {
            urls = [urls];
        }
        const isActive = urls.indexOf(location.pathname) >= 0;
        const className = isActive ? 'border-primary-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
        return (<Link to={urls[0]} className={className}>{text}</Link>)
    }

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({open}) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-8 w-auto"
                                        src={logo}
                                        alt="Juris Medicus"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src={logo}
                                        alt="Juris Medicus"
                                    />
                                </div>
                                <div className="ml-6 flex space-x-8">
                                    {buildLink('Enter Time', ['/time-entry', '/'])}
                                    {buildLink('Calendar', '/calendar')}
                                </div>
                            </div>
                            <div className="ml-6 flex items-center">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                    onClick={performLogout}
                                >
                                    Logout
                                    <LogoutIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                    </div>

                </>
            )}
        </Disclosure>
    )
}
