/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useContext, useEffect, useMemo, useState} from 'react'
import {ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon,} from '@heroicons/react/solid'
import {ExclamationCircleIcon,} from '@heroicons/react/outline'
import {Menu, Transition} from '@headlessui/react'
import moment from 'moment';
import {EnvContext} from "../../App";
import CalendarDayLgComponent from "./CalendarDayLgComponent";
import CalendarDaySmComponent from "./CalendarDaySmComponent";
import EventsSlideOverComponent from "./EventsSlideOverComponent";
import CenteredSingleActionModal from "../modals/CenteredSingleActionModal";

import CalendarService from "../../services/CalendarService";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const middleOfCalendarOnLoad = new moment()
    .startOf('month')
    .startOf("week")
    .add(15, 'days');

function formatMonth(m) {
    return m.format('YYYY MMMM');
}

function parseMonth(str) {
    return moment(str, 'YYYY MMMM')
}

function formatMoment(m) {
    return m.format('YYYY-MM-DD');
}

function getStartOfCalendar(mom) {
    return mom.startOf('month').startOf("week");
}

function getEndOfCalendar(mom) {
    return mom.endOf('month').endOf("week");
}


export default function CalendarComponent() {
    const envProvider = useContext(EnvContext);
    const calendarService = useMemo(() => {
        return new CalendarService(envProvider)
    }, [envProvider])

    const [calendarMonth, setCalendarMonth] = useState(formatMonth(middleOfCalendarOnLoad))
    const [loadingEvents, setLoadingEvents] = useState(false)
    const [events, setEvents] = useState({})
    const [selectedDate, setSelectedDate] = useState(null)
    const [alertState, setAlertState] = useState({
        title: "Error",
        message: "An error occurred loading calendar.",
        action: "Okay",
        show: false
    });
    const calendarState = useMemo(() => {
        setLoadingEvents(true)
        const startOfMonth = parseMonth(calendarMonth).set('date', 1)
        const start = getStartOfCalendar(startOfMonth.clone())
        const end = getEndOfCalendar(startOfMonth.clone())
        const dates = []
        let date = start.clone();
        let todayHasBeenFound = false;
        let today = new Date();
        while (date.diff(end) < 0) {
            let isToday = false;
            if (!todayHasBeenFound && date.isSame(today, "day")) {
                isToday = true;
                todayHasBeenFound = true;
            }
            dates.push({
                date: formatMoment(date),
                isCurrentMonth: startOfMonth.isSame(date, 'month'),
                isToday: isToday,
            })
            date = date.add(1, 'days')
        }
        return {
            start: formatMoment(start),
            end: formatMoment(end),
            dates: dates
        }
    }, [calendarMonth, setLoadingEvents])


    useEffect(() => {
        calendarService.getEvents(calendarState.start, calendarState.end, (events) => {
            console.log("On success!", events);
            const eventDict = {};
            for (let e of events) {
                let current = calendarService.getMomentStart(e);
                let end = calendarService.getMomentEnd(e);
                if (!e.color) {
                    e.color = calendarService.getEventColor(e);
                }
                while (current.diff(end) < 0) {
                    const date = formatMoment(current);
                    const events = eventDict[date] || [];
                    events.push(e);
                    eventDict[date] = events;
                    current = current.add(1, 'days');
                }
            }
            setEvents(eventDict);
        }, (error) => {
            let errorMessage = 'Something went wrong. Please refresh the page and try again.';
            if (error) {
                errorMessage += ' (' + error + ')';
            }
            showErrorModal(errorMessage);
        }, () => {
            setLoadingEvents(false)
        });
    }, [calendarState, setLoadingEvents, calendarService])


    function getEvents(date) {
        return events[date] || [];
    }

    function goToToday() {
        setCalendarMonth(formatMonth(new moment()));
    }

    function goToPreviousMonth() {
        const month = parseMonth(calendarMonth).subtract('1', 'months')
        setCalendarMonth(formatMonth(month))
    }

    function goToNextMonth() {
        const month = parseMonth(calendarMonth).add('1', 'months')
        setCalendarMonth(formatMonth(month))
    }

    function onDateSelect(date) {
        setSelectedDate(date)
    }

    function dismissModal() {
        setAlertState(prevState => ({
            ...prevState,
            show: false
        }))
    }

    function showErrorModal(message) {
        setAlertState(prevState => ({
            ...prevState,
            message: message || "An error occurred loading calendar.",
            show: true
        }))
    }

    function getModalIcon() {
        return (<div
            className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
        </div>)
    }

    return (
        <div className="lg:flex lg:h-full lg:flex-col">
            <CenteredSingleActionModal
                show={alertState.show}
                title={alertState.title}
                message={alertState.message}
                actionName={alertState.action}
                icon={getModalIcon()}
                onDismiss={() => dismissModal()}
            />
            <header
                className="relative z-20 flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none">
                <h1 className="text-lg font-semibold text-gray-900">
                    <time>{calendarMonth}</time>
                </h1>
                <div className="flex items-center">
                    <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                        <button
                            type="button"
                            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            onClick={goToPreviousMonth}

                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                            onClick={goToToday}
                        >
                            Today
                        </button>
                        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
                        <button
                            type="button"
                            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            onClick={goToNextMonth}

                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                    <Menu as="div" className="relative ml-6 md:hidden">
                        <Menu.Button
                            className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Open menu</span>
                            <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                        </Menu.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({active}) => (
                                            <a
                                                href="src/components/calendar/CalendarComponent#"
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                )}
                                                onClick={goToToday}
                                            >
                                                Go to today
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </header>
            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div
                    className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">un</span>
                    </div>
                    <div className="bg-white py-2">
                        M<span className="sr-only sm:not-sr-only">on</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">ue</span>
                    </div>
                    <div className="bg-white py-2">
                        W<span className="sr-only sm:not-sr-only">ed</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">hu</span>
                    </div>
                    <div className="bg-white py-2">
                        F<span className="sr-only sm:not-sr-only">ri</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">at</span>
                    </div>
                </div>
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto relative">
                    <div
                        className={"hidden w-full lg:grid lg:grid-cols-7 lg:gap-px lg:grid-rows-" + calendarState.dates / 7}>
                        {calendarState.dates.map((day) => (
                            <CalendarDayLgComponent key={day.date}
                                                    date={day.date}
                                                    isToday={day.isToday}
                                                    isCurrentMonth={day.isCurrentMonth}
                                                    isSelected={selectedDate === day.date}
                                                    events={getEvents(day.date)}
                                                    hideDetails={loadingEvents}
                                                    onSelect={() => onDateSelect(day.date)}/>
                        ))}
                    </div>
                    <div
                        className={"isolate grid w-full grid-cols-7 gap-px lg:hidden grid-rows-" + +calendarState.dates / 7}>
                        {calendarState.dates.map((day) => (
                            <CalendarDaySmComponent key={day.date}
                                                    date={day.date}
                                                    isToday={day.isToday}
                                                    isCurrentMonth={day.isCurrentMonth}
                                                    isSelected={selectedDate === day.date}
                                                    events={getEvents(day.date)}
                                                    hideDetails={loadingEvents}
                                                    onSelect={() => onDateSelect(day.date)}
                            />
                        ))}
                    </div>
                    {loadingEvents && <div role="status"
                                           className="absolute w-full h-full flex justify-center items-center bg-gray-50 opacity-70 lg:pb-72">
                        <svg aria-hidden="true"
                             className="w-8 h-8 lg:w-20 lg:h-20 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600"
                             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"/>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"/>
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>}
                </div>
            </div>
            <EventsSlideOverComponent date={selectedDate} events={getEvents(selectedDate)}
                                      onClose={() => setSelectedDate(null)}/>
        </div>
    )
}
